export const daysFromToday = (date: Date): string => {
  const diff = new Date(Date.now()).getTime() - date.getTime();
  let days = Math.ceil(diff / (1000 * 3600 * 24));
  days = Math.max(days, 1);

  return days == 1 ? `${days} day` : `${days} days`;
};

export function formatDate(
  date: string,
  options: Intl.DateTimeFormatOptions = {}
) {
  return new Intl.DateTimeFormat(navigator.language, options).format(
    new Date(date)
  );
}
