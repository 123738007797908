import { Opaque } from "type-fest";
import { camelCase } from "lodash";

export type AccessToken = Opaque<string>;

type StringAsKeys = {
  [key: string]: any;
};

export type ProductCodeToNameMap = {
  [key: string]: string;
};

export type SnakeToCamelCase<S extends string> =
  S extends `${infer T}_${infer U}`
    ? `${T}${Capitalize<SnakeToCamelCase<U>>}`
    : S;

export function convertToCamelCase<
  T extends StringAsKeys,
  V extends StringAsKeys
>(source: T): V {
  const keys = Object.keys(source);
  const result: StringAsKeys = {};

  keys.forEach((key) => {
    if (
      typeof source[key] === "object" &&
      !Array.isArray(source[key]) &&
      source[key] !== null
    ) {
      result[camelCase(key)] = convertToCamelCase(source[key]);
    } else {
      result[camelCase(key)] = source[key];
    }
  });

  return result as V;
}

export enum BannerType {
  Alert,
  Info,
}
